// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const VideoEditorForm = () => {
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [phone, setPhone] = useState('');
//     const [coverLetter, setCoverLetter] = useState('');
//     const [portfolioLink, setPortfolioLink] = useState('');
//     const [message, setMessage] = useState('');
//     const [error, setError] = useState('');
//     const [secretKey, setSecretKey] = useState('');
//     const [coverLetterError, setCoverLetterError] = useState('');

//     useEffect(() => {
//         const key = localStorage.getItem('secret_key');
//         setSecretKey(key || ''); // Default to empty string if not found
//     }, []);

//     const handleCoverLetterChange = (e) => {
//         const input = e.target.value;
//         const wordCount = input.trim().split(/\s+/).length;

//         if (wordCount > 500) {
//             setCoverLetterError('Cover letter exceeds the 500-word limit. Please shorten it.');
//         } else {
//             setCoverLetterError('');
//             setCoverLetter(input);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (coverLetterError) {
//             setError('Please fix the errors before submitting.');
//             return;
//         }

//         try {
//             const response = await axios.post('https://profit-backend.onrender.com/api/video-editor/', {
//                 name,
//                 email,
//                 phone,
//                 cover_letter: coverLetter,
//                 portfolio_link: portfolioLink,
//                 secret_key: secretKey,
//             });
//             setMessage(response.data.success);
//             setName('');
//             setEmail('');
//             setPhone('');
//             setCoverLetter('');
//             setPortfolioLink('');
//             setError('');
//         } catch (error) {
//             setError('You already submitted the application');
//             setName('');
//             setEmail('');
//             setPhone('');
//             setCoverLetter('');
//             setPortfolioLink('');
//             setMessage('');
//         }
//     };

//     return (
//         <div className="flex justify-center items-center min-h-screen to-indigo-500">
//             <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
//             <br/>
//             <br/>
//             <br/>
//                 <h2 className="text-3xl font-bold text-center text-[#00416A] mb-6">Apply for Video Editor</h2>
//                 <form onSubmit={handleSubmit} className="space-y-6">
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-1">Full Name:</label>
//                         <input
//                             type="text"
//                             value={name}
//                             onChange={(e) => setName(e.target.value)}
//                             required
//                             className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
//                             placeholder="Enter your full name"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-1">Email Address:</label>
//                         <input
//                             type="email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             required
//                             className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
//                             placeholder="you@example.com"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number:</label>
//                         <input
//                             type="text"
//                             value={phone}
//                             onChange={(e) => setPhone(e.target.value)}
//                             required
//                             className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
//                             placeholder="Enter your phone number"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-1">Cover Letter (500 words max):</label>
//                         <textarea
//                             value={coverLetter}
//                             onChange={handleCoverLetterChange}
//                             required
//                             className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
//                             placeholder="Write a brief cover letter (500 words max)"
//                         />
//                         {coverLetterError && (
//                             <p className="text-red-600 mt-2">{coverLetterError}</p>
//                         )}
//                     </div>
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-1">Portfolio Link:</label>
//                         <input
//                             type="url"
//                             value={portfolioLink}
//                             onChange={(e) => setPortfolioLink(e.target.value)}
//                             required
//                             className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
//                             placeholder="e.g, LinkedIn, Facebook, Website, etc."
//                         />
//                     </div>
//                     <button
//                         type="submit"
//                         className="w-full bg-[#00416A] text-white py-3 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
//                     >
//                         Submit Application
//                     </button>
//                 </form>
//                 {message && <p className="mt-4 text-green-600 text-center">{message}</p>}
//                 {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
//             </div>
//         </div>
//     );
// };

// export default VideoEditorForm;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VideoEditorForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [maritalStatus, setMaritalStatus] = useState(''); // New state for marital status
    const [coverLetter, setCoverLetter] = useState('');
    const [portfolioLink, setPortfolioLink] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [coverLetterError, setCoverLetterError] = useState('');

    useEffect(() => {
        const key = localStorage.getItem('secret_key');
        setSecretKey(key || ''); // Default to empty string if not found
    }, []);

    const handleCoverLetterChange = (e) => {
        const input = e.target.value;
        const wordCount = input.trim().split(/\s+/).length;

        if (wordCount > 500) {
            setCoverLetterError('Cover letter exceeds the 500-word limit. Please shorten it.');
        } else {
            setCoverLetterError('');
            setCoverLetter(input);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (coverLetterError) {
            setError('Please fix the errors before submitting.');
            return;
        }

        try {
            const response = await axios.post('https://profit-backend.onrender.com/api/video-editor/', {
                name,
                email,
                phone,
                marital_status: maritalStatus, // Send marital status to the API
                cover_letter: coverLetter,
                portfolio_link: portfolioLink,
                secret_key: secretKey,
            });
            setMessage(response.data.success);
            setName('');
            setEmail('');
            setPhone('');
            setMaritalStatus(''); // Reset marital status
            setCoverLetter('');
            setPortfolioLink('');
            setError('');
        } catch (error) {
            setError('You already submitted the application');
            setName('');
            setEmail('');
            setPhone('');
            setMaritalStatus(''); // Reset marital status
            setCoverLetter('');
            setPortfolioLink('');
            setMessage('');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen to-indigo-500">
            <div className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg">
                <br />
                <br />
                <br/>
                <h3 className="text-2xl font-bold text-center text-gray-800 mb-4">Job Details</h3>
                <div className="space-y-4">
                    <h4 className="text-lg font-semibold">Introduction:</h4>
                    <p className="text-gray-700">ProfitComparison.com, a leading startup in Pakistan focused on empowering individuals and businesses through comparative financial insights, invites independent researchers to contribute to the platform's growth and usability enhancement. We aim to build a user-centric and innovative platform and are seeking your expertise to help achieve this vision.</p>
                    <h4 className="text-lg font-semibold">Who Can Apply?</h4>
                    <ul className="text-gray-700">
                     <li>1. Independent researchers (not employed under our payroll).</li>
                     <li>2. Academics, professionals, or students with relevant experience in usability studies, UX design, data analytics, or platform development.</li>
                     <li>3. Individuals capable of conducting high-quality, actionable research.</li>
                     </ul>
                    
                    <h4 className="text-lg font-semibold">Research Objectives:</h4>
                    <p className="text-gray-700">The focus of this collaboration is to enhance the usability and functionality of ProfitComparison.com. Suggested research areas include, but are not limited to:</p>
                    <ul className="text-gray-700">
                     <li>1. <strong>User Experience and Design:</strong> Analyze current platform usability and recommend UI (User Interface) and UX (User Experience) improvements.</li>
                     <li>2. <strong>Comparative Analysis Algorithms:</strong> Develop innovative methods to improve accuracy and speed of financial comparisons.</li>
                     <li>3. <strong>Market Behavior Studies:</strong> Study consumer behavior trends in Pakistan for financial product comparisons.</li>
                     <li>4. <strong>AI and Machine Learning Integration:</strong> Explore the role of AI in personalizing comparison results.</li>
                     <li>5. <strong>Accessibility and Inclusion:</strong> Ensure the platform is inclusive for individuals with diverse needs and preferences.</li>
                     </ul>

                    <h4 className="text-lg font-semibold">Funding Opportunity:</h4>
                    <p className="text-gray-700">We offer funding based on the level and scope of your research. Funding will be allocated as follows:</p>
                    <ul className="text-gray-700">
                     <li>1. Short-term research.</li>
                     <li>2. Long-term research.</li>
                     <li>3. Exceptional proposals may receive additional support.</li>
                     </ul>

                     <h4 className="text-lg font-semibold">Submission Requirements:</h4>
                    <p className="text-gray-700">Applicants are required to submit a research proposal containing the following:</p>
                    <ul className="text-gray-700">
                     <li>1. <strong>Title:</strong> A concise and descriptive title of your proposed research.</li>
                     <li>2. <strong>Abstract:</strong> A summary of the research objectives, methods, and expected outcomes (max 300 words).</li>
                     <li>3. <strong>Research Questions/Problem Statement:</strong> Clearly define the problem or research question(s).</li>
                     <li>4. <strong>Methodology:</strong> Outline the approach and tools you will use.</li>
                     <li>5. <strong>Timeline: </strong> Provide a realistic timeline for completion.</li>
                     <li>6. <strong>Budget: </strong> Estimated costs of conducting the research (if any).</li>
                     <li>7. <strong>Deliverables:  </strong> Specify the expected outputs or findings.</li>
                     </ul>

                     <h4 className="text-lg font-semibold">Evaluation Criteria</h4>
                    <p className="text-gray-700">Proposals will be evaluated based on:</p>
                    <ul className="text-gray-700">
                     <li>1. Relevance to ProfitComparison.com's objectives.</li>
                     <li>2. Innovativeness and feasibility.</li>
                     <li>3. Clarity and quality of methodology.</li>
                     <li>4. Potential impact on platform usability.</li>
                     </ul>

                     <h4 className="text-lg font-semibold">Application Process</h4>
                     <ul className="text-gray-700">
                     <li>1. Submit your proposal to research@profitcomparison.com.</li>
                     <li>2. Include your CV or professional profile.</li>
                     </ul>
                </div>
                <br />
                <h2 className="text-3xl font-bold text-center text-[#00416A] mb-6">Quick Apply</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Full Name:</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="Enter your full name"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Email Address:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="you@example.com"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number:</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="Enter your phone number"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Marital Status:</label>
                        <select
                            value={maritalStatus}
                            onChange={(e) => setMaritalStatus(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                        >
                            <option value="">Select your marital status</option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Widowed">Widowed</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Cover Letter (500 words max):</label>
                        <textarea
                            value={coverLetter}
                            onChange={handleCoverLetterChange}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="Write a brief cover letter (500 words max)"
                        />
                        {coverLetterError && (
                            <p className="text-red-600 mt-2">{coverLetterError}</p>
                        )}
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Portfolio Link:</label>
                        <input
                            type="url"
                            value={portfolioLink}
                            onChange={(e) => setPortfolioLink(e.target.value)}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            placeholder="e.g, LinkedIn, Facebook, Website, etc."
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-[#00416A] text-white py-3 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                        Submit Application
                    </button>
                </form>
                {message && <p className="mt-4 text-green-600 text-center">{message}</p>}
                {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
                
            </div>
        </div>
    );
};

export default VideoEditorForm;
